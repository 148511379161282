import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, useTheme, useMediaQuery } from '@mui/material'
import TopPage from '../../layout/TopPage'
import OrderForm from '../form/OrderForm'
import FilterBar from '../../../shared/components/FilterBar'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import {
  useGroupStore,
  useWorkspaceSettingsStore,
  useDeviceContentStore,
} from '../../../shared/store'
import OrdersList from './OrdersList'
import NanoSelectDateRange from '../../../shared/components/NanoSelectDateRange'
import NanoSelectMultiple from '../../../shared/components/NanoSelectMultiple'
import { client } from '../../../shared/apiClient'
import dayjs from 'dayjs'
import { QuickDateRangePicker } from '../../../shared/components/QuickDateRangePicker'

const propTypes = {}

const defaultProps = {}

function Orders() {
  const { t } = useTranslation()
  const [form, setForm] = useState({ isOpen: false, orderId: null })
  const disableOrderEdition =
    useWorkspaceSettingsStore((state) => state.getSetting('disableOrderEdition'))?.value === 'true'
  const disableOrdersSettings =
    useWorkspaceSettingsStore((state) => state.getSetting('disableOrders'))?.value === 'true'
  const [disableOrders, setDisableOrders] = useState(null)
  const dataDeviceContent = useDeviceContentStore((state) => state.dataDeviceContent)
  const [deviceContentType, setDeviceContentType] = useState([])
  /** @type {[dayjs.Dayjs, dayjs.Dayjs]} */
  const defaultDateRange = useMemo(() => [dayjs().subtract(1, 'month'), dayjs()], [])
  const [dateFilter, setDateFilter] = useState(defaultDateRange)
  const [groupsFilters, setGroupsFilters] = useState([])
  const dataGroup = useGroupStore((state) => state.dataGroup)
  const theme = useTheme()
  const mobileFormat = !useMediaQuery(theme.breakpoints.up('sm'))
  const handleEdit = (orderId) => {
    setForm({ isOpen: true, orderId })
  }

  useEffect(() => {
    useDeviceContentStore.getState().fetchData()
    client.GET('/v1/workspace/device-content-types', {}).then((result) => {
      setDeviceContentType(
        result.data.map((dc) => ({
          name: dc.device_content_type,
          value: dc.id, //, dc.fixed_quantity_tons, dc.min_quantity_tons
        }))
      )
    })
  }, [])

  useEffect(() => {
    if (disableOrdersSettings) {
      setDisableOrders('no_access_orders_section')
    } else if (dataDeviceContent.length === 0 && deviceContentType.length === 0)
      setDisableOrders('no_config_orders_section')
    else setDisableOrders(null)
  }, [dataDeviceContent, deviceContentType, disableOrdersSettings])

  useEffect(() => {
    if (!dataGroup.length) {
      useGroupStore.getState().fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <TopPage
        title={mobileFormat ? null : t('orders_title')}
        actions={
          <Stack direction="column">
            <ButtonAdd
              disabled={disableOrdersSettings}
              text={t('orders_button_title')}
              onClick={() => {
                setForm({ isOpen: true, orderId: null })
              }}
            />
          </Stack>
        }
      />
      <FilterBar searchFieldPlaceHolder={t('search')}>
        <QuickDateRangePicker
          value={dateFilter}
          onPick={(value) => setDateFilter(value)}
          minDate={dayjs().subtract(2, 'year')}
        />
        <NanoSelectDateRange
          placeholder="date"
          value={dateFilter}
          onChange={(value) => setDateFilter(value)}
          onReset={() => setDateFilter(defaultDateRange)}
          dateRangePickerProps={{ disableFuture: false, minDate: dayjs().subtract(2, 'year') }}
        />
        <NanoSelectMultiple
          placeholder="group_other"
          options={dataGroup
            .filter((g) => g.is_poi)
            .map((g) => ({ name: g.group_name, value: g.group_id }))}
          value={groupsFilters}
          handleSelect={setGroupsFilters}
        />
      </FilterBar>

      <OrdersList
        disableOrderEdition={disableOrderEdition}
        handleEdit={handleEdit}
        dateFilter={dateFilter}
        groupsFilters={groupsFilters}
        disableOrders={disableOrders}
      />

      {form.isOpen && (
        <OrderForm
          isOpen={form.isOpen}
          orderId={form.orderId}
          onClickCloseButton={() => {
            setForm({ isOpen: false, orderId: null })
          }}
        />
      )}
    </div>
  )
}

Orders.propTypes = propTypes
Orders.defaultProps = defaultProps

export default Orders
