import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  Button,
  DialogContent,
  CircularProgress,
  Box,
  Alert,
} from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../shared/icons/CloseIcon.svg'
import { ReactComponent as ArrowLeftIconBig } from '../../../shared/icons/ArrowLeftIconBig.svg'
import ButtonNext from '../../../shared/components/ButtonNext'
import StepCircularProgress from '../../../shared/components/StepCircularProgress'
import OrderFormStepWhen from './OrderFormStepWhen'
import OrderFormStepWhere from './OrderFormStepWhere'
import OrderFormStepWhat from './OrderFormStepWhat'
import OrderFormResume from './OrderFormResume'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import { getRequest, patchRequest, postRequest } from '../../../shared/apiClient'
import { ToastContext } from '../../../shared/contexts'
import { LoadingButton } from '@mui/lab'
import SuccessPage from '../../../shared/components/SuccessPage'
import { useForm, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { orderDatesToString } from '../utils/orderUtils'
import isNumber from '../../../shared/utils/isNumber'
import { sendEvent } from '../../../shared/utils/analyticsUtils'

const StepActionType = Object.freeze({
  Cancel: 'cancel',
  Next: 'next',
  Previous: 'previous',
  Submit: 'submit',
})

/**
 * Steps array contains an object with 5 properties:
 * - title (string): the translation key
 * - leftAction (StepActionType): the button to be displayed at top left (mainly "Cancel" or "Previous")
 * - rightAction (StepActionType): the button to be displayed at top right (mainly "Next" or "Submit")
 * - content: function that returns the component to be displayed in the form
 * - validateAndUpdateOrder: function called when the form is submitted, each step takes care of updating the how object according to what it displays. Return boolean.
 */
const steps = (t) => [
  {
    title: t('order_form_step_when_title'),
    leftAction: StepActionType.Cancel,
    rightAction: StepActionType.Next,
    content: ({ order, control, watch, setValue, getValues }) => (
      <OrderFormStepWhen
        order={order}
        control={control}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
      />
    ),
    validateAndUpdateOrder({ order, setOrder, payload }) {
      const dateDayJS = payload.when_dates?.length === 2 ? payload.when_dates[0] : payload.when_date

      let dateRangeEndDayJS = null

      if (payload.when_dates?.length === 2) {
        dateRangeEndDayJS = payload.when_dates[1] ? payload.when_dates[1] : payload.when_dates[0]
      }

      setOrder({
        ...order,
        timeSlot: payload.when_time_slot,
        date: new Date(Date.UTC(dateDayJS.year(), dateDayJS.month(), dateDayJS.date())),
        date_range_end: dateRangeEndDayJS
          ? new Date(
              Date.UTC(
                dateRangeEndDayJS.year(),
                dateRangeEndDayJS.month(),
                dateRangeEndDayJS.date()
              )
            )
          : null,
      })
      return true
    },
  },
  {
    title: t('order_form_step_where_title'),
    leftAction: StepActionType.Previous,
    rightAction: StepActionType.Next,
    content: ({ order, control }) => <OrderFormStepWhere order={order} control={control} />,
    validateAndUpdateOrder({ order, setOrder, payload, replace }) {
      const devices = []
      // get all devices not combined
      payload.where_devices_groups.forEach((obj) => {
        if (obj.section === 'group') {
          devices.push(...obj.devices.filter((device) => device.is_combined !== true))
        } else {
          if (obj.is_combined !== true) {
            devices.push(obj)
          }
        }
      })
      // remove duplicates
      const deviceReferencesSet = new Set(devices.map((device) => device.device_reference))
      const devicesWithoutDuplicates = Array.from(deviceReferencesSet).map((deviceRef) => {
        return devices.find((device) => device.device_reference === deviceRef)
      })
      setOrder({ ...order, devicesSelected: devicesWithoutDuplicates })
      // use the "replace" function to inject the "devices" to be displayed in the "What" step into "fields".
      // https://react-hook-form.com/docs/usefieldarray
      replace(devicesWithoutDuplicates)
      return true
    },
  },
  {
    title: t('order_form_step_what_title'),
    leftAction: StepActionType.Previous,
    rightAction: StepActionType.Next,
    content: ({
      order,
      control,
      workspaceOrderData,
      fields,
      remove,
      watch,
      setDisabledNextButton,
    }) => (
      <OrderFormStepWhat
        order={order}
        control={control}
        workspaceOrderData={workspaceOrderData}
        fields={fields}
        remove={remove}
        watch={watch}
        setDisabledNextButton={setDisabledNextButton}
      />
    ),
    validateAndUpdateOrder({ order, setOrder, payload, workspaceSettingsStore, toastContext, t }) {
      const totalTonnage = payload.silos.reduce((sum, obj) => sum + parseFloat(obj.what_tonnage), 0)

      /**
       * lastest checks
       * - orderThreshold: check that the total quantity does not exceed the authorized quantity
       * - maxArticlesNb: checks whether there are too many different contents and content types in the order
       */
      if (payload.silos) {
        const orderThreshold = parseInt(workspaceSettingsStore.getSetting('orderThreshold')?.value)
        const maxArticlesNb = parseInt(workspaceSettingsStore.getSetting('maxArticlesNb')?.value)
        if (isNumber(orderThreshold) && totalTonnage > orderThreshold) {
          toastContext.sendMessage(
            t('order_form_total_quantity_overflow_v2', {
              orderThreshold: t('number_workspace_filling_unit', {
                value: orderThreshold,
              }),
            }),
            'error'
          )
          return false
        }

        if (isNumber(maxArticlesNb)) {
          const set = new Set()
          for (const s of payload.silos) {
            set.add(`${s.what_content}_${s.what_content_type}`)
          }
          if (set.size > maxArticlesNb) {
            toastContext.sendMessage(
              t('order_form_total_article_overflow', { maxArticlesNb }),
              'error'
            )
            return false
          }
        }
      }

      setOrder({
        ...order,
        totalTonnage,
        silos: payload.silos?.map((item) => ({
          id: item.device_reference,
          device_name: item.device_name,
          farm_name: item.farm_name,
          tonnage: parseFloat(item.what_tonnage),
          comment: item.what_comment,
          content: {
            name: item.what_content,
            type: item.what_content_type,
            drug: item.what_drug,
            supplement: {
              name: item.what_supplement,
            },
          },
        })),
      })
      return true
    },
  },
  {
    title: t('order_form_resume_title'),
    leftAction: StepActionType.Previous,
    rightAction: StepActionType.Submit,
    content: ({ order, control }) => <OrderFormResume order={order} control={control} />,
    validateAndUpdateOrder({ order, setOrder, payload }) {
      setOrder({ ...order, comment: payload.resume_comment })
      return true
    },
  },
]

const propTypes = {
  orderId: PropTypes.string,
  deviceReference: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  onClickCloseButton: PropTypes.func.isRequired,
}

const defaultProps = {
  orderId: null,
  deviceReference: [],
  isOpen: false,
}

/**
 * This component is an orchestrator for:
 * - retrieve useful data for the creation/edition
 * - manage loading status
 * - display the correct step (and success page)
 * - initialize react-hook-form and create the global form
 * - update the order object which will be used by each step
 * - create or update the order on the server
 */
/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function OrderForm({ orderId, deviceReference, isOpen, onClickCloseButton }) {
  /**
   * Hooks
   */
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [disabledNextButton, setDisabledNextButton] = useState(false)
  const [loadingSubmitOrder, setLoadingSubmitOrder] = useState(false)
  const [order, setOrder] = useState({})
  const [workspaceOrderData, setWorkspaceOrderData] = useState({
    deviceContents: [],
    deviceContentTypes: [],
    drugs: [],
    supplement: [],
  })
  const toastContext = useContext(ToastContext)
  const [displayOrderCreatedSuccessfullyPage, setDisplayOrderCreatedSuccessfullyPage] =
    useState(false)
  const { handleSubmit, control, watch, setValue, getValues } = useForm()
  // hooks to manage WHAT step with multiple form (https://react-hook-form.com/docs/usefieldarray)
  const { fields, replace, remove } = useFieldArray({ control, name: 'silos' })

  /**
   * Get current form step
   */
  const step = steps(t)[index]

  /**
   * OnSubmit will
   * - update ORDER object
   * - POST or PATCH ORDER in backend if last step OR go next step
   * @param {Object} payload
   */
  const onSubmit = async (payload) => {
    // update order object for current step
    const workspaceSettingsStore = useWorkspaceSettingsStore.getState()
    if (
      step.validateAndUpdateOrder({
        order,
        setOrder,
        payload,
        replace,
        workspaceSettingsStore,
        toastContext,
        t,
      })
    ) {
      // If last step post/patch order OR go next step
      if (index + 1 === steps(t).length) {
        setLoadingSubmitOrder(true)
        const body = {
          ...order,
          comment: payload.resume_comment,
        }
        const promise = Promise.resolve(
          orderId
            ? patchRequest(`v1/orders/${orderId}/`, { ...body, status: 'updated' })
            : postRequest('v1/orders/', body)
        )
        promise
          .then(() => {
            orderId ? sendEvent('order_updated') : sendEvent('submit_order')
            setDisplayOrderCreatedSuccessfullyPage(true)
          })
          .catch((err) => {
            toastContext.sendMessage(t(err.message), 'error')
          })
          .finally(() => {
            setLoadingSubmitOrder(false)
          })
      } else {
        setIndex(index + 1)
      }
    }
  }

  /**
   * getData - Get useful data for the order creation or modification process:
   * - workspace settings
   * - contens / content types / drugs & supplement
   * - fetch the order if we are in edition ("orderId" param not null)
   */
  const getData = async () => {
    setLoading(true)

    await useWorkspaceSettingsStore.getState().fetchData()
    const responses = await Promise.all([
      getRequest('v1/workspace/device-contents'),
      getRequest('v1/workspace/device-content-types'),
      // @TODO: do not call if not useful for this workspace
      getRequest('v2/drugs'),
      getRequest('v2/supplements'),
    ])

    setWorkspaceOrderData({
      // @TODO : device content & device content type in store
      deviceContents: responses[0]?.data,
      deviceContentTypes: responses[1]?.data,
      drugs: responses[2]?.data,
      supplement: responses[3]?.data,
    })

    // fetch order for editing
    if (orderId) {
      const orderResponse = await getRequest(`v1/orders/${orderId}`)
      const _order = orderResponse.data

      /**
       * Fetch devices object because device object in order's silo property is information-poor
       *
       * @TODO:
       * change get orders (or create get orders v2) ws to return the classic device object
       * or all informations useful for creating/editing an order
       */

      if (_order.silos && _order.silos?.length) {
        const requests = _order.silos?.map((d) => getRequest(`v1/devices/${d.id}`))
        const deviceResponses = await Promise.all(requests)
        setOrder({
          ..._order,
          devicesSelected: deviceResponses.map((r) => r.data),
        })
      }
    }

    // fetch device to inject in the order
    if (deviceReference.length) {
      const requests = deviceReference?.map((deviceRef) => getRequest(`v1/devices/${deviceRef}`))
      const deviceResponses = await Promise.all(requests)
      setOrder({
        ...order,
        devicesSelected: deviceResponses.map((r) => r.data),
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isOpen ? null : (
    <Dialog fullScreen onClick={(e) => e.stopPropagation()} open={isOpen}>
      {loading ? (
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <CircularProgress color="inherit" />
        </DialogContent>
      ) : displayOrderCreatedSuccessfullyPage ? (
        <DialogContent
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        >
          <SuccessPage
            title={
              orderId ? t('order_form_edit_success_title') : t('order_form_create_success_title')
            }
            description={t('order_form_create_success_description')}
            buttonTitle={t('order_form_create_success_back')}
            onClose={() => {
              navigate(0)
            }}
          />
        </DialogContent>
      ) : (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="start">
              {step.leftAction === StepActionType.Cancel && (
                <Button
                  variant="outlined"
                  sx={{ borderColor: 'grey.main', color: 'black !important' }}
                  startIcon={<CloseIcon />}
                  onClick={onClickCloseButton}
                >
                  {t('cancel')}
                </Button>
              )}
              {step.leftAction === StepActionType.Previous && (
                <Stack>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: 'grey.main', color: 'black !important' }}
                    startIcon={<ArrowLeftIconBig />}
                    onClick={() => {
                      setIndex(index - 1)
                    }}
                  >
                    {t('previous')}
                  </Button>
                  <Button
                    variant="text"
                    style={{ fontSize: '12px' }}
                    sx={{ color: 'error.main' }}
                    onClick={onClickCloseButton}
                  >
                    {t('order_form_cancel_button')}
                  </Button>
                </Stack>
              )}
              <Stack direction="row" alignItems="center" spacing={2}>
                <StepCircularProgress step={index} nbSteps={steps(t).length} />
                <Stack>
                  <Typography variant="h5">{t('order_form_title')}</Typography>
                  <Typography variant="body2">{orderDatesToString({ order })}</Typography>
                  <Typography variant="body2">
                    {'timeSlot' in order && t(order.timeSlot)}
                  </Typography>
                </Stack>
              </Stack>
              {step.rightAction === StepActionType.Next && (
                <ButtonNext type="submit" text={t('next')} disabled={disabledNextButton} />
              )}
              {step.rightAction === StepActionType.Submit && (
                <LoadingButton type="submit" loading={loadingSubmitOrder}>
                  {orderId ? t('order_form_edit_button') : t('order_form_submit_button')}
                </LoadingButton>
              )}
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack alignItems="center" spacing={2}>
              <Typography variant="h1">{step.title}</Typography>
              {step.content({
                order,
                control,
                workspaceOrderData,
                fields,
                remove,
                watch,
                setValue,
                getValues,
                setDisabledNextButton,
              })}
            </Stack>
          </DialogContent>
        </Box>
      )}
      {workspaceOrderData.deviceContentTypes.length === 0 &&
        workspaceOrderData.deviceContents.length === 0 && (
          <Alert severity="info"> {t('no_access_orders_section')} </Alert>
        )}
    </Dialog>
  )
}

OrderForm.propTypes = propTypes
OrderForm.defaultProps = defaultProps

export default OrderForm
