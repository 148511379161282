import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import dayjs from 'dayjs'
import i18n from '../../config/i18n'
import { useTranslation } from 'react-i18next'

/**
 * @param {Object} props
 * @param {[dayjs.Dayjs, dayjs.Dayjs]} props.value
 * @param {(value: [dayjs.Dayjs, dayjs.Dayjs]) => void} props.onPick
 * @param {dayjs.Dayjs} [props.minDate]
 *
 */
export const QuickDateRangePicker = ({ value, onPick, minDate }) => {
  const { t } = useTranslation()

  const quickRanges = useMemo(() => {
    const all = {
      key: 'all',
      value: [minDate, dayjs()],
      label: t('all_time'),
    }

    const ranges = [
      {
        key: 'last-4-months',
        value: [dayjs().subtract(4, 'month'), dayjs()],
        label: formatRelativeTime(4, 'months'),
      },
      {
        key: 'last-1-month',
        value: [dayjs().subtract(1, 'month'), dayjs()],
        label: formatRelativeTime(1, 'months'),
      },
      {
        key: 'last-14-days',
        value: [dayjs().subtract(14, 'day'), dayjs()],
        label: formatRelativeTime(2, 'weeks'),
      },
      {
        key: 'last-7-days',
        value: [dayjs().subtract(7, 'day'), dayjs()],
        label: formatRelativeTime(1, 'weeks'),
      },
    ]

    const available = ranges.filter(
      (quickRange) =>
        !minDate || quickRange.value[0].isAfter(minDate) || quickRange.value[0].isSame(minDate)
    )
    if (available.length < ranges.length) {
      return [all, ...available]
    } else {
      return available
    }
  }, [minDate, t])

  const activeQuickRange = quickRanges.find((quickRange) => isRangeEqual(quickRange.value, value))

  return (
    <ToggleButtonGroup
      value={activeQuickRange?.key}
      exclusive
      onChange={(event, key) => {
        const value = quickRanges.find((quickRange) => quickRange.key === key)?.value
        if (value) onPick(value)
      }}
    >
      {quickRanges.map((quickRange) => (
        <ToggleButton key={quickRange.key} value={quickRange.key}>
          <Typography variant="h5">{quickRange.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

function isRangeEqual(range1, range2) {
  return range1.every((date, index) => date.isSame(range2[index], 'day'))
}

function formatRelativeTime(value, unit) {
  const duration = {
    [unit]: value,
  }
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DurationFormat/format
  try {
    // @ts-ignore
    return new Intl.DurationFormat(i18n.resolvedLanguage, { style: 'long' }).format(duration)
  } catch (e) {
    // Fallback for browsers that do not support Intl.DurationFormat
    return new Intl.RelativeTimeFormat(i18n.resolvedLanguage, {
      numeric: 'always',
      style: 'narrow',
    }).format(-value, unit)
  }
}
