import {
  TableCell,
  Skeleton,
  Stack,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '../icons/AddIcon.svg'
import { patchRequest } from '../apiClient'
import { ToastContext } from '../contexts'
import NanoChip from './NanoChip'
import AddDeviceOrGroupDialog from './AddDeviceOrGroupDialog'
import DisplayDeviceOrGroupDialog from './DisplayDeviceOrGroupDialog'
import { sendEvent } from '../utils/analyticsUtils'

const propTypes = {
  loading: PropTypes.bool,
  device_groups_to_check: PropTypes.arrayOf(PropTypes.shape({})),
  devices_to_check: PropTypes.arrayOf(PropTypes.shape({})),
  alert_id: PropTypes.string.isRequired,
  doubleChip: PropTypes.bool,
  /** Desactive the option of deletion or addition */
  readOnly: PropTypes.bool,
  maxWidth: PropTypes.number,
}

const defaultProps = {
  loading: false,
  devices_to_check: [],
  device_groups_to_check: [{}],
  doubleChip: false,
  readOnly: false,
  maxWidth: 175,
}

function GroupsDevicesCell({
  loading,
  devices_to_check: devicesToCheck,
  device_groups_to_check: deviceGroupsToCheck,
  alert_id: alertId,
  doubleChip,
  readOnly,
  maxWidth,
}) {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  // const [openGroupsDialog, setOpenGroupsDialog] = useState(false)
  const [groupsValue, setGroupsValue] = useState([])
  const [openNewAccessModal, setOpenNewAccessModal] = useState(false)
  const theme = useTheme()

  // NEED UseEffect Hook because no deviceGroupsToCheck && deviceGroupsToCheck on first render (loading state is handled here)
  useEffect(() => {
    setGroupsValue(
      deviceGroupsToCheck
        .map((g) => ({ label: g.group_name, id: g.group_id, section: 'group', ...g }))
        .concat(
          devicesToCheck.map((d) => ({
            label: d.device_name,
            id: d.device_reference,
            section: 'bin',
            ...d,
          }))
        )
    )
  }, [deviceGroupsToCheck, devicesToCheck])

  const handleAlertChange = (deviceGroups) => {
    // build payload
    const payload = {
      device_groups_to_check_ids: deviceGroups
        .filter((groups) => groups.section === 'group')
        .map((group) => group.id),
      devices_to_check_ids: deviceGroups
        .filter((devices) => devices.section === 'bin')
        .map((d) => d.device_id),
    }
    patchRequest(`v1/alerts/${alertId}/`, payload)
      .then(() => {
        sendEvent('alert_updated')
        setGroupsValue(deviceGroups)
        toastContext.sendMessage(t('alert_snackbar_success'))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const limitTag = useMediaQuery(theme.breakpoints.up('xl')) && doubleChip
  const widthChip = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <TableCell onClick={(e) => e.stopPropagation()}>
      {loading ? (
        <Skeleton variant="rounded" width={100} height={20} />
      ) : (
        <>
          <Stack direction="row" alignItems="center" spacing={1} alignContent="flex-start">
            {!readOnly && (
              <IconButton
                sx={{ backgroundColor: 'grey.main' }}
                onClick={() => setOpenNewAccessModal(true)}
              >
                <AddIcon />
              </IconButton>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              alignContent="flex-start"
              flexWrap="wrap"
            >
              {!!groupsValue.length && (
                <NanoChip
                  onDelete={
                    readOnly
                      ? null
                      : () =>
                          handleAlertChange(groupsValue.filter((x) => x.id !== groupsValue[0].id))
                  }
                  sx={{
                    backgroundColor: 'grey.main',
                    '& .MuiChip-label': { maxWidth: widthChip ? maxWidth : 100 },
                  }}
                  section={groupsValue[0].section}
                  label={groupsValue[0].label}
                  tooltip={groupsValue[0].label}
                />
              )}
              {groupsValue.length >= 2 && limitTag && (
                <NanoChip
                  onDelete={
                    readOnly
                      ? null
                      : () =>
                          handleAlertChange(groupsValue.filter((x) => x.id !== groupsValue[1].id))
                  }
                  sx={{
                    backgroundColor: 'grey.main',
                    '& .MuiChip-label': { maxWidth: 100 },
                  }}
                  section={groupsValue[1].section}
                  label={groupsValue[1].label}
                  tooltip={groupsValue[1].label}
                />
              )}
              {((groupsValue.length > 2 && limitTag) || (!limitTag && groupsValue.length > 1)) && (
                <IconButton onClick={() => setOpenNewAccessModal(true)}>
                  <Typography variant="body1" color="text.secondary">
                    +{groupsValue.length - (limitTag ? 2 : 1)}
                  </Typography>
                </IconButton>
              )}
            </Stack>
          </Stack>
          {readOnly ? (
            <DisplayDeviceOrGroupDialog
              title={t('farm_list')}
              onClose={() => setOpenNewAccessModal(false)}
              open={openNewAccessModal}
              value={groupsValue}
            />
          ) : (
            <AddDeviceOrGroupDialog
              open={openNewAccessModal}
              title={t('alert_add_silos')}
              onClose={() => setOpenNewAccessModal(false)}
              onAdd={handleAlertChange}
              value={groupsValue}
            />
          )}
        </>
      )}
    </TableCell>
  )
}

GroupsDevicesCell.propTypes = propTypes
GroupsDevicesCell.defaultProps = defaultProps

export default GroupsDevicesCell
