import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid, TableCell } from '@mui/material'
import NanoSection from '../../../shared/components/NanoSection'
import OrderCharacteristics from './OrderCharacteristics'
import NanoTable from '../../../shared/components/NanoTable'
import { useWorkspaceSettingsStore } from '../../../shared/store'

const columns = (t) => [
  {
    header: t('group_one'),
    width: 200,
    format: (row) => <TableCell>{row.farm_name}</TableCell>,
  },
  {
    header: t('order_device_silo'),
    width: 200,
    format: (row) => <TableCell>{row.device_name}</TableCell>,
  },
  {
    header: t('device_content'),
    width: 200,
    format: (row) => <TableCell>{row.content?.name}</TableCell>,
  },
  {
    header: t('device_content_type'),
    width: 200,
    format: (row) => <TableCell>{row.content?.type}</TableCell>,
  },
  {
    header: t('drug'),
    width: 200,
    format: (row) => <TableCell>{row.content?.drug ? row.content?.drug : '-'}</TableCell>,
  },
  {
    header: t('supplements'),
    width: 200,
    format: (row) => (
      <TableCell>{row.content?.supplement?.name ? row.content?.supplement?.name : '-'}</TableCell>
    ),
  },
  {
    header: t('comment'),
    width: 200,
    format: (row) => <TableCell>{row.comment ? row.comment : '-'}</TableCell>,
  },
  {
    header: t('quantity'),
    width: 100,
    format: (row) => (
      <TableCell>{t('number_workspace_filling_unit', { value: row.tonnage })}</TableCell>
    ),
  },
]

const propTypes = {
  order: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  fromForm: PropTypes.bool,
  handleValidateOrder: PropTypes.func,
}

const defaultProps = {
  order: {},
  loading: false,
  fromForm: false,
  handleValidateOrder: null,
}

function Order({ order, loading, fromForm, handleValidateOrder }) {
  const { t } = useTranslation()
  const state = useWorkspaceSettingsStore()
  const showDrugs = state.getSetting('showDrugs')?.value === 'true'
  const showSupplements = state.getSetting('showSupplements')?.value === 'true'

  let columnsCopy = columns(t)
  if (showDrugs === false) {
    columnsCopy = columnsCopy.filter(function (obj) {
      return obj.header !== 'drug'
    })
  }
  if (showSupplements === false) {
    columnsCopy = columnsCopy.filter(function (obj) {
      return obj.header !== 'supplement'
    })
  }

  return (
    <>
      <Grid container direction="column">
        <NanoSection title={t('informations')}>
          <OrderCharacteristics
            order={order}
            loading={loading}
            fromForm={fromForm}
            handleValidateOrder={handleValidateOrder}
          />
        </NanoSection>
        <NanoSection title={t('details')}>
          <NanoTable
            columns={columnsCopy}
            data={order?.silos ?? []}
            loading={loading}
            useStyledTable={false}
          />
        </NanoSection>
      </Grid>
    </>
  )
}

Order.propTypes = propTypes
Order.defaultProps = defaultProps

export default Order
