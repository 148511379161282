import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ToastContext, DialogContext } from '../../../shared/contexts'
import { client } from '../../../shared/apiClient'
import { debounce } from '@mui/material/utils'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import NanoTable from '../../../shared/components/NanoTable'
import SimpleCell from '../../../shared/components/SimpleCell'
import AdminDeviceContentGroupCell from './AdminDeviceContentGroupCell'
import MoreActionAdminDeviceContentType from './MoreActionAdminDeviceContentType'
import MoreActionAdminDeviceContent from './MoreActionAdminDeviceContent'
import AdminDeviceContentModal from './AdminDeviceContentModal'
import AdminDeviceContentTypeModal from './AdminDeviceContentTypeModal'
import AdminDrugModal from './AdminDrugModal'
import MoreActionAdminDrug from './MoreActionAdminDrug'
import MoreActionAdminSupplement from './MoreActionAdminSupplement'
import AdminSupplementModal from './AdminSupplementModal'
import ImportListModal from './ImportListModal'
import { useWorkspaceSettingsStore } from '../../../shared/store'
import FilterBar from '../../../shared/components/FilterBar'
import ButtonWhite from '../../../shared/components/ButtonWhite'
const propTypes = {}

const defaultProps = {}

const columnsDeviceContent = (handleEdit, filterByContentGroup) => {
  const settingsAsBool = filterByContentGroup === 'true'
  return [
    {
      header: 'device_content',
      width: 400,
      format: (row) => <SimpleCell loading={row.loading} data={row.device_content} />,
    },
    {
      header: 'group_other',
      width: 800,
      hide: !settingsAsBool,
      format: (row, key) => (
        <AdminDeviceContentGroupCell loading={row.loading} key={key} {...row} />
      ),
    },
    {
      header: 'actions',
      format: (row) => (
        <MoreActionAdminDeviceContent id={row.id} handleEdit={() => handleEdit(row)} />
      ),
      width: 55,
    },
  ]
}

const columnsDeviceContentType = (t, handleEdit) => [
  {
    header: 'device_content_type',
    width: 250,
    format: (row) => <SimpleCell loading={row.loading} data={row.device_content_type} />,
  },
  {
    header: 'min_quantity_tons',
    width: 250,
    format: (row) => (
      <SimpleCell
        loading={row.loading}
        data={t('number_workspace_filling_unit', { value: row.min_quantity_tons })}
      />
    ),
  },
  {
    header: 'actions',
    format: (row) => (
      <MoreActionAdminDeviceContentType id={row.id} handleEdit={() => handleEdit(row)} />
    ),
    width: 55,
  },
]

const columnsDrug = [
  {
    header: 'drug',
    width: 250,
    format: (row) => <SimpleCell loading={row.loading} data={row.drug} />,
  },
  {
    header: 'actions',
    format: (row) => <MoreActionAdminDrug id={row.drug} />,
    width: 55,
  },
]

const columnsSupplement = [
  {
    header: 'supplements',
    width: 250,
    format: (row) => <SimpleCell loading={row.loading} data={row.supplement} />,
  },
  {
    header: 'actions',
    format: (row) => <MoreActionAdminSupplement id={row.supplement} />,
    width: 55,
  },
]

function AdminDataPage() {
  const [valueTab, setValueTab] = useState(0)
  const [dataType, setDataType] = useState([])
  const [dataFeed, setDataFeed] = useState([])
  const [dataDrug, setDataDrug] = useState([])
  const [dataSupplement, setDataSupplement] = useState([])
  const [dataLoading, setDataLoading] = useState(true)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [formImportIsOpen, setFormImportIsOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState({})
  const [remoteRowCount, setRemoteRowCount] = useState(15)
  const [searchDeviceContent, setSearchDeviceContent] = useState(null)
  const [searchDeviceContentType, setSearchDeviceContentType] = useState(null)
  const [searchDrugs, setSearchDrugs] = useState(null)
  const [searchSupplements, setSearchSupplements] = useState(null)
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const state = useWorkspaceSettingsStore()
  const filterByContentGroup = state.getSetting('filterContentByGroup')?.value
  const showDrugs = state.getSetting('showDrugs')?.value === 'true'
  const showSupplements = state.getSetting('showSupplements')?.value === 'true'

  const handleChange = (_, newValue) => {
    setValueTab(newValue)
    setSearchDeviceContent(null)
    setSearchDeviceContentType(null)
    setSearchDrugs(null)
    setSearchSupplements(null)
  }
  const fetchValue = async (v) => {
    setDataLoading(true)
    const query = { page: 1, page_size: 15, search: v }

    const result = await client.GET('/v1/workspace/device-contents', { params: { query } })
    const { results, ...pagination } = result.data
    setDataFeed(results.sort((a, b) => b.groups.length - a.groups.length))
    setRemoteRowCount(pagination.rowCount)
    setDataLoading(false)
  }
  const getOptionsDelayed = useMemo(() => debounce(fetchValue, 500), [])

  const getData = async (v) => {
    setDataLoading(true)

    setPage(1)
    const query = { page: 1, page_size: 15, search: v }

    const responses = await Promise.all([
      client.GET('/v1/workspace/device-contents', { params: { query } }),
      client.GET('/v1/workspace/device-content-types', {}),
      client.GET('/v2/drugs', {}),
      client.GET('/v2/supplements', {}),
    ])
    if (responses.length === 4) {
      setRemoteRowCount(responses[0].data.rowCount)
      setDataFeed(responses[0].data.results)
      setDataType(responses[1].data)
      setDataDrug(responses[2].data)
      setDataSupplement(responses[3].data)
    }
    setDataLoading(false)
  }

  const loadMoreRows = () => {
    const query = { page: page + 1, page_size: 15, searchDeviceContent }
    client.GET('/v1/workspace/device-contents', { params: { query } }).then((result) => {
      const { results, ...pagination } = result.data
      setDataFeed([...dataFeed, ...results])
      setPage(pagination.page)
    })
  }
  const value = useMemo(
    () => ({
      deletedOrEdited: () => {
        getData()
      },
    }),
    []
  )

  useEffect(() => {
    if (searchDeviceContent) getOptionsDelayed(searchDeviceContent)
    else if (searchDeviceContentType)
      setDataType(
        dataType?.filter((data) =>
          data.device_content_type.toLowerCase().includes(searchDeviceContentType.toLowerCase())
        )
      )
    else if (searchDrugs)
      setDataDrug(
        dataDrug?.filter((data) => data.drug.toLowerCase().includes(searchDrugs.toLowerCase()))
      )
    else if (searchSupplements)
      setDataSupplement(
        dataSupplement?.filter((data) =>
          data.supplement.toLowerCase().includes(searchSupplements.toLowerCase())
        )
      )
    else getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDeviceContent, searchDeviceContentType, searchDrugs, searchSupplements])

  const handleEdit = (row) => {
    setDefaultValues(row)
    setFormIsOpen(true)
  }

  const tabsInfo = [
    {
      value: 'device_content',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDeviceContent,
      searchFieldValue: searchDeviceContent,
    },
    {
      value: 'device_content_type',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDeviceContentType,
      searchFieldValue: searchDeviceContentType,
    },
    showDrugs && {
      value: 'drug',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchDrugs,
      searchFieldValue: searchDrugs,
    },
    showSupplements && {
      value: 'supplements',
      searchFieldPlaceholder: 'search',
      searchFieldAction: setSearchSupplements,
      searchFieldValue: searchSupplements,
    },
  ].filter(Boolean)

  return (
    <>
      <FilterBar
        withTabs
        isSearch={tabsInfo[valueTab].searchFieldPlaceholder.length > 0}
        searchFieldPlaceholder={tabsInfo[valueTab].searchFieldPlaceholder}
        searchFieldValue={tabsInfo[valueTab].searchFieldValue}
        searchFieldAction={tabsInfo[valueTab].searchFieldAction}
      >
        <Tabs variant="scrollable" value={valueTab} onChange={handleChange}>
          {tabsInfo.map((tab) => (
            <Tab key={tab.value} sx={{ padding: 0 }} label={t(tab.value)} />
          ))}
        </Tabs>

        <ButtonAdd
          text={t('create')}
          onClick={() => {
            setFormIsOpen(true)
          }}
        />
        <ButtonWhite
          withAdd
          variant="outlined"
          text={t('import')}
          onClick={() => setFormImportIsOpen(true)}
        />
      </FilterBar>

      {tabsInfo[valueTab].value === 'device_content' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsDeviceContent(handleEdit, filterByContentGroup)}
            data={dataFeed}
            loading={dataLoading}
            fetchPage={loadMoreRows}
            remoteRowCount={remoteRowCount}
          />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'device_content_type' && (
        <DialogContext.Provider value={value}>
          <NanoTable
            columns={columnsDeviceContentType(t, handleEdit)}
            data={dataType}
            loading={dataLoading}
          />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'drug' && (
        <DialogContext.Provider value={value}>
          <NanoTable columns={columnsDrug} data={dataDrug} loading={dataLoading} />
        </DialogContext.Provider>
      )}
      {tabsInfo[valueTab].value === 'supplements' && (
        <DialogContext.Provider value={value}>
          <NanoTable columns={columnsSupplement} data={dataSupplement} loading={dataLoading} />
        </DialogContext.Provider>
      )}
      {formIsOpen && (
        <>
          {tabsInfo[valueTab].value === 'device_content' && (
            <AdminDeviceContentModal
              isOpen={formIsOpen}
              withGroup={filterByContentGroup === 'true'}
              row={defaultValues}
              onSuccess={(operation) => {
                toastContext.sendMessage(t(`admin_device_content_snackbar_alert_${operation}`))
                getData()
              }}
              onClose={() => {
                setFormIsOpen(false)
                setDefaultValues({})
              }}
            />
          )}
          {tabsInfo[valueTab].value === 'device_content_type' && (
            <AdminDeviceContentTypeModal
              isOpen={formIsOpen}
              row={defaultValues}
              onSuccess={() => {
                toastContext.sendMessage(t('admin_device_content_type_snackbar_alert_created'))
                getData()
              }}
              onClose={() => {
                setFormIsOpen(false)
                setDefaultValues({})
              }}
            />
          )}
          {tabsInfo[valueTab].value === 'drug' && (
            <AdminDrugModal
              isOpen={formIsOpen}
              onSuccess={() => {
                toastContext.sendMessage(t('admin_drug_snackbar_alert_created'))
                getData()
              }}
              onClose={() => {
                setFormIsOpen(false)
                setDefaultValues({})
              }}
            />
          )}
          {tabsInfo[valueTab].value === 'supplements' && (
            <AdminSupplementModal
              isOpen={formIsOpen}
              onSuccess={() => {
                toastContext.sendMessage(t('admin_supplements_snackbar_alert_created'))
                getData()
              }}
              onClose={() => {
                setFormIsOpen(false)
                setDefaultValues({})
              }}
            />
          )}
        </>
      )}

      {formImportIsOpen && (
        <ImportListModal
          type={tabsInfo[valueTab].value}
          isOpen={formImportIsOpen}
          onDataImported={() => getData()}
          onClose={() => {
            setFormImportIsOpen(false)
          }}
        />
      )}
    </>
  )
}

AdminDataPage.propTypes = propTypes
AdminDataPage.defaultProps = defaultProps

export default AdminDataPage
