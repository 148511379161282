import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import NanoDialog from '../../../shared/components/NanoDialog'
import { ToastContext } from '../../../shared/contexts'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { CloseIcon, CopyIcon, TrashIcon } from '../../../shared/icons/index'
import { client } from '../../../shared/apiClient'
import ButtonAdd from '../../../shared/components/ButtonAdd'
import NanoAvatar from '../../../shared/components/NanoAvatar'
import { Promise } from 'bluebird'

const propTypes = {
  onDataImported: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  /** @type {PropTypes.Requireable<import('../models/adminModel').AdminDataTabs>} */
  type: PropTypes.oneOf(['device_content', 'device_content_type', 'drug', 'supplement']),
}

const defaultProps = {
  isOpen: false,
  row: {},
}

/**
 * @param {PropTypes.InferProps<propTypes>} props
 */
function ImportListModal({ onDataImported, isOpen, onClose, type }) {
  const { t } = useTranslation()
  const toastContext = useContext(ToastContext)
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState(null)
  const [step, setStep] = useState(1)
  const [error, setError] = useState(0)
  const [formattedList, setFormattedList] = useState([])

  const handleClose = () => {
    reset()
    onClose()
  }

  const reset = () => {
    setList(null)
    setStep(1)
    setError(0)
  }

  useEffect(() => {
    if (list) {
      const formattedList = list.replace(/,/g, '\n').replace(/;/g, '\n').replace(/\t/g, '\n')
      setList(formattedList)
    }
  }, [list])

  useEffect(() => {
    if (formattedList.length === 0) reset()
  }, [formattedList])

  const onSubmit = async () => {
    setIsLoading(true)
    if (step === 1) {
      setIsLoading(false)
      const tempTab = list.split('\n').filter(Boolean)
      const uniques = [...new Set(tempTab)].sort()
      setFormattedList(uniques)
      setStep(step + 1)
    } else {
      const errors = []
      try {
        // await Promise.all(formattedList.map(async (item) => {

        Promise.map(
          formattedList,
          async (item) => {
            let promise
            if (type === 'device_content') {
              promise = client.POST('/v1/workspace/device-contents', {
                body: { device_content: item },
              })
            }
            if (type === 'device_content_type') {
              // @ts-ignore
              promise = client.POST('/v1/workspace/device-content-types', {
                body: { device_content_type: item },
              })
            }
            if (type === 'drug') {
              promise = client.POST('/v2/drugs', { body: { drug: item } })
            }
            if (type === 'supplement') {
              promise = client.POST('/v2/supplements', { body: { supplement: item } })
            }
            try {
              await promise
            } catch (err) {
              errors.push(item) // Stocke l'erreur dans le tableau
            }
          },
          { concurrency: 5 }
        )
          .then(() => {
            if (errors.length === 0) {
              setIsLoading(false)
              reset()
              toastContext.sendMessage(t(`admin_${type}_snackbar_alert_created`))
              onClose()
            } else {
              setIsLoading(false)
              reset()
              setList(errors.join(','))
              setError(errors.length)
            }
          })
          .catch(() => {
            setIsLoading(false)
            reset()
            toastContext.sendMessage(t(`admin_${type}_snackbar_error_alert_created`, 'error'))
            onClose()
          })
          .finally(() => {
            onDataImported()
          })
      } catch {}
    }
  }

  const handlePaste = (event) => {
    event.preventDefault() // Empêche la saisie manuelle
    const clipboardData = event.clipboardData || window.Clipboard
    setList(clipboardData.getData('text'))
  }
  const onDelete = (index) => {
    const temp = [...formattedList]
    temp.splice(index, 1)
    setFormattedList(temp)
  }
  return !isOpen ? null : (
    <NanoDialog open={isOpen} onClose={handleClose} title={t(`import_a_list_${type}`)}>
      <Box>
        <Stack spacing={3}>
          {step === 2 ? (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography color="text.secondary">
                  {t(`${type}_list_with_count`, { count: formattedList.length })}
                </Typography>
                <ButtonAdd
                  variant="outlined"
                  sx={{ borderColor: 'grey.main', color: 'black !important' }}
                  startIcon={<TrashIcon />}
                  text={t('delete_all')}
                  onClick={() => reset()}
                />
              </Stack>
              <Stack>
                {formattedList.map((dc, index) => {
                  return (
                    <Stack key={index} spacing={1}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        {dc}
                        <IconButton onClick={() => onDelete(index)}>
                          <NanoAvatar sx={{ width: 16, height: 16 }}>
                            <CloseIcon stroke="white" />
                          </NanoAvatar>
                        </IconButton>
                      </Stack>
                      <Divider />
                    </Stack>
                  )
                })}
              </Stack>
            </>
          ) : (
            <TextField
              required
              multiline
              error={error && true}
              helperText={
                error > 0 && t(`admin_${type}_snackbar_error_alert_created`, { count: error })
              }
              rows={3}
              fullWidth
              onPaste={handlePaste}
              placeholder={'\n' + t(`copy_paste_${type}_list_ph`)}
              InputProps={{
                sx: { backgroundColor: 'transparent' },
                startAdornment: (
                  <InputAdornment position="start">
                    <CopyIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                setList(event.target.value)
              }}
              value={list}
            />
          )}
          <LoadingButton type="submit" loading={isLoading} onClick={onSubmit} disabled={!list}>
            {t('import_and_validate')}
          </LoadingButton>
        </Stack>
      </Box>
    </NanoDialog>
  )
}

ImportListModal.propTypes = propTypes
ImportListModal.defaultProps = defaultProps

export default ImportListModal
