import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { client } from '../apiClient'
import NanoAutocomplete from './NanoAutocomplete'
import { Typography } from '@mui/material'

async function fetchOptions(groupFilter) {
  const query = { group_ids: groupFilter }
  const responses = await client.GET('/v2/drugs', { params: { query } })
  return responses.data.map((drug) => ({
    label: drug.drug,
    id: drug.drug_id,
    section: 'drug',
  }))
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    section: PropTypes.string,
  }),
  groupFilter: PropTypes.string,
}

const defaultProps = {
  value: null,
  groupFilter: null,
}

/**
 ** @param {PropTypes.InferProps<propTypes>} props
 */
function SelectDrugs({ onChange, value, groupFilter, ...props }) {
  const { t } = useTranslation()

  return (
    <NanoAutocomplete
      placeholder={t('drug')}
      noOptionsText={
        <Typography sx={{ pt: 3 }} align="center">
          {t('no_drugs_to_add')}
        </Typography>
      }
      fetchOptions={() => fetchOptions(groupFilter)}
      onChange={onChange}
      value={value}
      {...props}
      sx={{ maxHeight: 300, overflow: 'auto' }}
    />
  )
}

SelectDrugs.propTypes = propTypes
SelectDrugs.defaultProps = defaultProps

export default SelectDrugs
